import {autoserialize, autoserializeAs} from 'cerialize';
import {PrintSizeModel} from './print-size.model';
import {ISpreadContentTypeBody} from '../interfaces/spread-content-type-body.interface';
import {ICollectionViewItem} from '../interfaces/collection-view-item.interface';
import {ITableItem} from '@relayter/rubber-duck';
import {IDataFieldsModel} from '../interfaces/datafields-model.interface';
import {ISearchIndexScore} from '../interfaces/search-index-score.interface';
import {FileTypeUtil, IFileType} from '../../classes/file-type.util';
import {GetFilePathPipe} from '../../pipes/get-file-path';
import {AssetsUtil} from '../../classes/assets.util';
import {ICardMediaType} from '@relayter/rubber-duck';
import {AssetFileTypePipe} from '../../pipes/asset-file-type.pipe';
import {FileModel} from './file.model';

export enum EAssetType {
    IMAGE = 'Image',
    VIDEO = 'Video',
    OTHER = 'Other',
}

class AssetFilesModel {
    @autoserializeAs(FileModel) public source: FileModel;
    @autoserializeAs(FileModel) public thumbnail: FileModel;
    @autoserializeAs(FileModel) public preview: FileModel;
}

export class AssetModel implements ISpreadContentTypeBody, ICollectionViewItem, ITableItem, IDataFieldsModel, ISearchIndexScore {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public type: string;
    @autoserializeAs(Date) public updatedAt: Date;
    @autoserializeAs(Date) public createdAt: Date;
    @autoserialize public width: number;
    @autoserialize public height: number;
    @autoserialize public size: string;
    @autoserializeAs(AssetFilesModel) public files: AssetFilesModel;
    @autoserialize public _path: string;
    @autoserialize public processing: boolean;
    @autoserialize public exportTypes: string[];
    @autoserializeAs(PrintSizeModel) public printSize: PrintSizeModel;
    @autoserialize public rin: string;
    @autoserialize public primary: boolean = false;
    @autoserializeAs(Object) public dataFields: Record<string, any>;
    @autoserialize public searchIndexScore: number;

    get path(): string {
        return this._path;
    }

    get printSizeName(): string {
        return this.printSize ? this.printSize.width + ' x  ' + this.printSize.height + ' mm ' + this.printSize.dpi + ' DPI' : '';
    }

    get resolution(): string {
        return (this.width && this.height) ? this.width + ' x ' + this.height + ' PX' : null;
    }

    get fileInfo(): string {
        let fileInfo = this.type.toUpperCase();
        if (this.size) {
            fileInfo += ` - ${this.size}`;
        }
        return fileInfo;
    }

    /**
     * Interface function
     * @return {string}
     */
    public getThumbnailURL(): string {
        if (this.processing) return FileTypeUtil.FILE_TYPE_IMAGES.PROCESSING;

        if (this.files?.thumbnail) {
            return this.files.thumbnail.url;
        }

        return GetFilePathPipe.getFilePath(this.files?.source?.extension || this.type);
    }

    /**
     * Interface function
     * @return {string}
     */
    public getThumbnailMedia(): ICardMediaType {
        if (this.processing) return {url: FileTypeUtil.FILE_TYPE_IMAGES.PROCESSING, type: EAssetType.IMAGE};

        if (this.files?.thumbnail) {
            return {url: this.files.thumbnail.url, type: AssetFileTypePipe.format(this.type)};
        }

        return {url: GetFilePathPipe.getFilePath(this.files?.source?.extension || this.type), type: EAssetType.IMAGE};
    }

    /**
     * Interface function
     * @return {AssetModel}
     */
    public getThumbnailAsset(): AssetModel {
        return this;
    }

    public getSourceURL(): string {
        return this.files?.source?.url;
    }

    /**
     * Interface function
     * @return {string}
     */
    public getTitle(): string {
        return this.name + this.type;
    }

    /**
     * Interface function
     * @return {string}
     */
    public getSubtitle(): string {
        return this.rin;
    }

    /**
     * Interface / Convenience method to get an array of loadable asset url for PIXI.loader
     * @param {string} [category]
     * @returns {string[]}
     */
    public getUniqueAssetUrls(category?: IFileType): string[] {
        if (category && !AssetsUtil.isAssetOfCategory(this, category)) {
            return [];
        }
        return [this.getThumbnailMedia().url];
    }

    /**
     * Interface method to check if this Content Type Body has loadable content (meaning it has assets that need to be loaded by PIXI.loader)
     * @returns {boolean}
     */
    public isLoadable(): boolean {
        return true;
    }

    public getSpreadEditorTitle(): string {
        return `${this.name}\n${this.rin}`;
    }
}

export class AssetPutModel {
    @autoserializeAs(Object) public dataFields: Record<string, any>;
    @autoserialize public s3Key?: string;

    constructor(dataFields: Record<string, any> = {}, s3Key?: string) {
        this.dataFields = dataFields;
        this.s3Key = s3Key;
    }
}
