<form [formGroup]="formGroup" *ngIf="formGroup">
    <div class="row">
        <nuc-form-field label="Name">
            <nuc-input formControlName="NAME" placeholder="Fill in the name"></nuc-input>
        </nuc-form-field>
        <nuc-form-field label="Description">
            <nuc-input formControlName="DESCRIPTION" placeholder="Fill in the description"></nuc-input>
        </nuc-form-field>
        <nuc-form-field label="Format">
            <nuc-dropdown formControlName="FORMAT"
                          [nullOption]="false"
                          [total]="formatOptions.length"
                          [items]="formatOptions"
                          placeholder="Select a format">
            </nuc-dropdown>
        </nuc-form-field>
    </div>

    <h2>Size</h2>
    <p>Set up size related data</p>
    <hr />

    <form [formGroup]="formGroup.controls.SIZE" class="row">
        <nuc-form-field label="Scale type">
            <nuc-dropdown formControlName="scaleType"
                          [nullOption]="false"
                          [total]="scaleTypes.length"
                          [items]="scaleTypes"
                          placeholder="Select a scale type">
            </nuc-dropdown>
        </nuc-form-field>

        <nuc-form-field label="Width">
            <nuc-input type="number" min="1" max="20000" formControlName="width" placeholder="Fill in the width"></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Height">
            <nuc-input type="number" min="1" max="20000" formControlName="height" placeholder="Fill in the height"></nuc-input>
        </nuc-form-field>
    </form>
</form>
