import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Toaster} from '../../../../classes/toaster.class';
import {AppConstants} from '../../../../app.constants';
import {
    DesignLibraryDetailModel,
    DesignLibraryModel
} from '../../../../modules/static-content-rulesets/models/api/design-library.model';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {
    BUTTON_TYPE,
    EColumnDataType,
    EColumnSize,
    ESelectionMode,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    IItemClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {
    ILibraryFormData,
    DesignLibraryFormComponent
} from '../../../../forms/library-form/design-library-form/design-library-form.component';
import {SVGLibraryFormComponent} from '../../../../forms/library-form/svg-library-form/svg-library-form.component';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {SortDirection} from '@angular/material/sort';
import {MonitoredJobsService} from '../../../../api/services/monitored-jobs.service';
import {NullUndefinedPipe} from '../../../../pipes/null-undefined.pipe';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {Subscription} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ItemUsedModel} from '../../../../models/api/item-used.model';
import {EEngineType} from '../../../../models/api/template.model';
import {DesignLibraryApiService} from '../../../../api/services/design-library.api.service';

@Component({
    selector: 'library-overview',
    templateUrl: 'library-overview.component.html',
    styleUrls: ['library-overview.component.scss'],
    providers: [PaginatorService]
})

export class LibraryOverviewComponent extends RLTableComponent implements OnInit {
    public readonly tableId = 'library-items-overview-table';

    public items: DesignLibraryModel[];
    public total: number;

    public sortProperty: string;
    public sortOrder: SortDirection;
    public pageIndex: number;
    public pageSize: number;
    public disableNextPage: boolean;

    public columns: ITableColumn[] = [{
        title: 'Name',
        key: 'name',
        sortProperty: 'name',
        size: EColumnSize.LARGE,
        dataType: EColumnDataType.STRING
    }, {
        title: 'Engine type',
        key: 'engineType',
        sortProperty: 'engineType',
        sortDuplicates: true,
        dataType: EColumnDataType.STRING
    }, {
        title: 'Date modified',
        key: 'updatedAt',
        sortProperty: 'updatedAt',
        format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.DEFAULT),
        sortDuplicates: true,
        dataType: EColumnDataType.DATE
    }, {
        title: 'Created by',
        key: 'createdBy.fullName',
        format: (value) => NullUndefinedPipe.transform(value, NullUndefinedPipe.defaultValues.DELETED_USER),
        size: EColumnSize.BASE,
        sortDuplicates: true,
        dataType: EColumnDataType.STRING
    }];
    public actions: ITableAction[];
    public readonly ESelectionMode = ESelectionMode;
    public designLibrarySubject: Subscription;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private designLibraryApiService: DesignLibraryApiService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private dialogService: NucDialogService,
                private fullModalService: FullModalService,
                private monitoredJobsService: MonitoredJobsService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.setTableActions();
        this.initFromRoute();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((pagination) => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) { // reset cursor when needed
                    this.newApiCursor.reset(pagination.pageIndex, this.tableSortOptions);
                }
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.updateUrl();
                this.getDesignLibraries();
            });
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_DESIGN_LIBRARIES)) {
            this.actions = [AppConstants.TABLE_ACTION_TYPES.DOWNLOAD];
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PATCH_DESIGN_LIBRARY)) {
            this.actions = this.actions ? this.actions.concat(AppConstants.TABLE_ACTION_TYPES.EDIT) : [AppConstants.TABLE_ACTION_TYPES.EDIT];
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_DESIGN_LIBRARY)) {
            this.actions = this.actions ? this.actions.concat(AppConstants.TABLE_ACTION_TYPES.DELETE) : [AppConstants.TABLE_ACTION_TYPES.DELETE];
        }
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;

        const pageIndex = params['pageIndex'] && parseInt(params['pageIndex'], 10) > 0 ? parseInt(params['pageIndex'], 10) : 1;
        this.sortProperty = params['sortProperty'];
        this.sortOrder = params['sortOrder'] && (params['sortOrder'] === 'asc' || params['sortOrder'] === 'desc') ?
            params['sortOrder'] : null;

        this.setPageIndex(pageIndex);
    }

    private setPageIndex(pageIndex = 1): void {
        this.newApiCursor.reset(pageIndex);
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private updateUrl(): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.TEMPLATING,
            AppConstants.CONTEXT_URL.DESIGN_LIBRARIES,
            new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder)]);
    }

    private getDesignLibraries(): void {
        this.designLibrarySubject?.unsubscribe();

        const cursor = this.newApiCursor.getCursor(this.pageIndex);
        const offset = (this.pageIndex === 1 || cursor._id) ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.designLibrarySubject = this.designLibraryApiService
            .find(
                this.pageSize,
                offset,
                this.tableSortOptions,
                cursor,
                undefined,
                { engineTypes: [EEngineType.INDESIGN, EEngineType.SVG] }
            )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                    next: (result: ARPagedResponseDataModel<DesignLibraryModel>) => {
                        ({items: this.items, total: this.total} = result);
                        this.disableNextPage = !result.hasNext;

                        if (this.items.length > 0) {
                            const item = this.items[this.items.length - 1];
                            this.newApiCursor.setCursor(this.pageIndex, item);
                        }
                    },
                    error: Toaster.handleApiError
                }
            );
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const eventItem = event.item as DesignLibraryModel;
        if (event.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
            this.onDeleteLibraryClicked(eventItem);
        } else {
            this.designLibrarySubject = this.designLibraryApiService
                .getDesignLibraryDetails(eventItem._id)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                        next: (result) => {
                            const library = result;
                            switch (event.action) {
                                case AppConstants.TABLE_ACTION_TYPES.DOWNLOAD:
                                    window.open(library.files.library.url);
                                    break;
                                case AppConstants.TABLE_ACTION_TYPES.EDIT:
                                    if (library.engineType === EEngineType.INDESIGN) this.openIndesignLibraryModal(library);
                                    else if (library.engineType === EEngineType.SVG ) this.openSVGLibraryModal(library)
                                    break;
                                default:
                                    Toaster.notYetImplementedError();
                                    break;
                            }
                        },
                        error: Toaster.handleApiError
                    }
                );
        }
    }

    public onDeleteLibraryClicked(item: DesignLibraryModel): void {
        this.designLibrarySubject = this.designLibraryApiService
            .getDesignLibraryInUse(item._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                    next: (usageObject: ItemUsedModel) => usageObject.usage ? this.openCannotDeleteDialog() : this.openDeleteDialog(item),
                    error: (err: ARApiError) => Toaster.handleApiError(err)
                }
            );
    }

    private openCannotDeleteDialog(): void {
        const cannotDeleteDialogConfig = new NucDialogConfigModel('Cannot delete', 'The design library is in use.');
        const cannotDeleteDialog = this.dialogService.openDialog(cannotDeleteDialogConfig);
        cannotDeleteDialogConfig.addAction('Close', BUTTON_TYPE.SECONDARY).subscribe(() => cannotDeleteDialog.close());
    }

    private openDeleteDialog(item: DesignLibraryModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete InDesign library',
            `Please confirm that you wish to delete ${item.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteLibraryItem(item);
        });
    }

    private reload(): void {
        this.setPageIndex(1);
        if (this.pageIndex !== 1) {
            this.updateUrl();
        }
    }

    private deleteLibraryItem(item: DesignLibraryModel): void {
        this.designLibrarySubject = this.designLibraryApiService.deleteOne(item._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    Toaster.success('Successfully deleted InDesign library');
                    this.reload();
                }, error: (err: ARApiError) => Toaster.handleApiError(err)
            });
    }

    public onTableRowClicked(event: IItemClickEvent): void {
        const library = event.item as DesignLibraryModel;
        this.router.navigate([
            AppConstants.CONTEXT_URL.TEMPLATING,
            AppConstants.CONTEXT_URL.DESIGN_LIBRARIES,
            library._id]
        );
    }

    public openIndesignLibraryModal(library?: DesignLibraryDetailModel): void {
        const config = library
            ? new FullModalConfig(
                'Edit InDesign library',
                'You can edit the information of InDesign library or upload a new file.',
                {library} as ILibraryFormData)
            : new FullModalConfig('Add new InDesign library', 'Add a new InDesign library by uploading it.', {});

        config.confirmClose = true;
        this.fullModalService.open(DesignLibraryFormComponent, config).afterClosed().subscribe((result) => {
            if (result) {
                !!result.jobId ? this.monitorJob(result.jobId) : this.getDesignLibraries();
            }
        });
    }

    public openSVGLibraryModal(library?: DesignLibraryDetailModel): void {
        const config = library
            ? new FullModalConfig(
                'Edit SVG library',
                'You can edit the information of SVG library or upload a new file.',
                {library} as ILibraryFormData)
            : new FullModalConfig('Add new SVG library', 'Add a new SVG library by uploading it.', {});

        config.confirmClose = true;
        this.fullModalService.open(SVGLibraryFormComponent, config).afterClosed().subscribe((result) => {
            if (result) {
                !!result.jobId ? this.monitorJob(result.jobId) : this.getDesignLibraries();
            }
        });
    }

    private monitorJob(jobId: string): void {
        this.monitoredJobsService.getJobMonitor(jobId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((jobModel: JobModel) => {
                if (jobModel.status === EJobStatus.DONE) {
                    this.getDesignLibraries();
                }
            });
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(event);
        this.setPageIndex();
    }

}
