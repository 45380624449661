import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';

export interface ICustomPackageFormGroup {
    NAME: FormControl<string>;
    DESCRIPTION: FormControl<string>;
    FORMAT: FormControl<DropdownItem<string>>;
    SIZE: FormGroup<{
        scaleType: FormControl<DropdownItem<string>>;
        width: FormControl<string>;
        height: FormControl<string>;
    }>
}

@Component({
    selector: 'workflow-configuration-action-custom-package-form',
    templateUrl: 'workflow-configuration-action-custom-package-form.component.html',
    styleUrls: ['workflow-configuration-action-custom-package-form.component.scss']
})
export class WorkflowConfigurationActionCustomPackageFormComponent {

    @Input() public scaleTypes: DropdownItem<string>[];
    @Input() public formatOptions: DropdownItem<string>[];
    @Input() public formGroup: FormGroup<ICustomPackageFormGroup>;
}
