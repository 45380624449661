import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../../classes/toaster.class';
import {AssetModel} from '../../../../models/api/asset.model';
import {AssetService, EAssetJobType} from '../../../../api/services/asset.service';
import {AppConstants} from '../../../../app.constants';
import {
    ILinkAssetsToProductViewData,
    LinkAssetsToProductsViewComponent
} from '../link-assets-to-products-view/link-assets-to-products-view.component';
import {AssetFormComponent} from '../../../../forms/asset-form/asset-form.component';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {RLBaseComponent} from '../../../../components/rl-base-component/rl-base.component';
import {BUTTON_TYPE, EColumnDataType, FullModalConfig, FullModalService, NucDialogConfigModel, NucDialogService} from '@relayter/rubber-duck';
import {filter, takeUntil} from 'rxjs/operators';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {EPropertySettingsContext} from '../../../../components/property-settings/property-settings.service';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {combineLatest, Subject, Subscription} from 'rxjs';
import {AdvancedFiltersDataService} from '../../../../api/services/advanced-filters.data-service';
import {CursorArray} from '../../../../api/api-cursor';
import {EStateContext, StateService} from '../../../../classes/state.service';
import {PageNavigationService} from '../../../../services/page-navigation.service';
import {EDataFieldCollectionName} from '../../../../app.enums';
import {DataFieldModel} from '../../../../models/api/data-field.model';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {ApiConstants} from '../../../../api/api.constant';
import {DataFieldsApiService} from '../../../../api/services/data-fields.api.service';

@Component({
    selector: 'assets-overview',
    templateUrl: 'asset-overview.component.html',
    styleUrls: ['asset-overview.component.scss'],
    providers: [AdvancedFiltersDataService, PaginatorService]
})

export class AssetOverviewComponent extends RLBaseComponent implements OnInit, OnDestroy {

    private static readonly DEFAULT_SORT_OPTIONS = [
        new DropdownItem('Date modified', 'updatedAt', true),
        new DropdownItem('Name', 'name', false)
    ];
    private static readonly SEARCH_SORT_OPTIONS = [new DropdownItem<string>('Relevance', 'relevance')];

    public readonly tableId = 'assets-overview-table';
    public DEFAULT_SIDEBAR_WIDTH = 450;

    public linkAssetsToProductsActive: boolean;
    public EPropertySettingsContext = EPropertySettingsContext;

    public loading: boolean = false;
    private _selectedAssets: string[] = [];
    @Output() public selectedAssetsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    public searchValue: string;
    public data: AssetModel[] = [];
    public selectedAsset: AssetModel;

    public sortingOptions = AssetOverviewComponent.DEFAULT_SORT_OPTIONS;

    public pageIndex: number;
    public pageSize: number;
    public sortDescending: boolean;
    public beforeSearchSortDescending: boolean;
    public sort: DropdownItem<string>;
    public beforeSearchSort: DropdownItem<string>;
    public totalItemCount: number;

    public dataFields: DataFieldModel[] = [];

    public assetsSubscription: Subscription;
    private onDestroySubject = new Subject<void>();

    private cursorArray: CursorArray;
    private filterValues: Record<string, any>;
    public disableNextPage: boolean = true;
    private firstLoad: boolean = true;

    public get selectedAssets(): string[] {
        return this._selectedAssets;
    }

    public set selectedAssets(val: string[]) {
        this._selectedAssets = val;
        this.selectedAssetsChange.emit(this._selectedAssets);
    }

    public get isSearching(): boolean {
        return !!this.searchValue || Object.keys(this.filterValues || {}).length > 0;
    }

    constructor(private router: Router,
                private route: ActivatedRoute,
                private assetService: AssetService,
                private dataFieldsService: DataFieldsApiService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private fullModalService: FullModalService,
                private filtersDataService: AdvancedFiltersDataService,
                private stateService: StateService,
                private pageNavigationService: PageNavigationService,
                private paginatorService: PaginatorService,
                private dialogService: NucDialogService) {
        super();
    }

    public ngOnInit(): void {
        this.initFromRoute();

        this.pageNavigationService.pageNavigationSubject$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((lastRoutes) => {
                // Check we return from the asset details page to this overview
                if (lastRoutes[0] && lastRoutes[0].match(`^${AppConstants.ASSETS_PATH}/${AppConstants.OBJECT_ID_REGEX}$`)) {
                    // Get pageIndex and cursorArray from previous state or initiate new value
                    const newPageIndex = this.stateService.getPageIndex(EStateContext.ASSET_OVERVIEW) || 1;
                    this.cursorArray = this.stateService.getCursorArray(EStateContext.ASSET_OVERVIEW) ||
                        new CursorArray(newPageIndex, this.sort.permanent);
                    this.setPageIndex(newPageIndex);
                } else {
                    // Initiate new value
                    if (!this.cursorArray) {
                        const newPageIndex = this.pageIndex || 1;
                        this.cursorArray = this.cursorArray || new CursorArray(newPageIndex, this.sort.permanent);
                        this.setPageIndex(newPageIndex);
                    }
                }
            });

        combineLatest([this.filtersDataService.getFilterValues(), this.paginatorService.getPagination(this.tableId)])
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(([filterValues, paginator]) => {
                if (this.filterValues !== filterValues) {
                    this.filterValues = filterValues;
                    if (!this.firstLoad) {
                        this.setPageIndex();
                        return;
                    }
                }

                this.firstLoad = false;

                if (this.pageSize !== paginator.pageSize) {
                    this.pageSize = paginator.pageSize;
                }

                if (this.pageIndex !== paginator.pageIndex) {
                    this.pageIndex = paginator.pageIndex;
                }

                if (this.pageIndex === 1) {
                    this.resetCursorArray();
                }

                this.stateService.setPageIndex(EStateContext.ASSET_OVERVIEW, this.pageIndex);

                this.updateUrl();

                this.getAssets();
            });

        this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.ASSET)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((dataFields) => {
                this.dataFields = dataFields;
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    /**
     * Gets the sorting options, search value from url or sets to default values.
     */
    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        const sortValue = params['sortProperty'] || this.sortingOptions[0].getValue();
        this.sort = this.sortingOptions.find((item) => item.getValue() === sortValue);
        this.beforeSearchSort = this.sort;
        this.sortDescending = params['sortOrder'] ? params['sortOrder'] === 'desc' : true;
        this.beforeSearchSortDescending = this.sortDescending;
        this.searchValue = params['search'];

        this.updateSortOptions();
    }

    /**
     * If allowed, get a list of paged assets
     */
    private getAssets(): void {
        if (!this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_ASSETS)) return;

        // prevent showing results from older slower calls
        this.assetsSubscription?.unsubscribe();

        const cursor = this.cursorArray.getCursor(this.pageIndex);

        this.assetsSubscription = this.assetService
            .getAssets(this.sortDescending, !this.searchValue ? this.sort.getValue() : null, this.searchValue, this.pageSize, 0,
                this.filterValues, cursor)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (res: ARPagedResponseDataModel<AssetModel>) => {
                    this.data = res.items;
                    this.disableNextPage = !res.hasNext;

                    if (res.items.length > 0) {
                        this.setCursor();
                    }
                },
                error: Toaster.handleApiError
            });
    }

    /**
     * Create a MatrixUrlParams so the url always has the correct amount of parameters
     * @return {MatrixUrlParams}
     */
    public createMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(null, null, this.sort.getValue(), this.sortDescending ? 'desc' : 'asc', this.searchValue || '');
    }

    protected updateUrl(): void {
        this.router.navigate([AppConstants.ASSETS_PATH, {...this.createMatrixUrl(), ...this.filterValues}], {replaceUrl: true});
    }

    private resetCursorArray(pageIndex = this.pageIndex): void {
        if (this.searchValue) {
            this.cursorArray.reset(pageIndex, true, EColumnDataType.NUMBER);
        } else {
            this.cursorArray.reset(pageIndex, this.sort.permanent);
        }
        this.stateService.setCursorArray(EStateContext.ASSET_OVERVIEW, this.cursorArray);
    }

    private setCursor(): void {
        if (this.searchValue) {
            this.cursorArray.setCursor(this.pageIndex, ApiConstants.SEARCH_INDEX_SCORE, this.data[this.data.length - 1]);
        } else {
            this.cursorArray.setCursor(this.pageIndex, this.sort.getValue(), this.data[this.data.length - 1]);
        }
        this.stateService.setCursorArray(EStateContext.ASSET_OVERVIEW, this.cursorArray);
    }

    /**
     * search input changed
     * @param {string} value searched for
     */
    public onSearch(value: string): void {
        if (this.searchValue !== value) {
            this.searchValue = value;
            this.setPageIndex();
        }

        this.updateSortOptions();
    }

    private updateSortOptions(): void {
        if (this.searchValue) {
            // Preserve values
            this.beforeSearchSort = this.sort;
            this.beforeSearchSortDescending = this.sortDescending;
            // Show sort on relevance
            this.sortingOptions = AssetOverviewComponent.SEARCH_SORT_OPTIONS;
            this.sort = this.sortingOptions[0];
            this.sortDescending = true;
        } else {
            this.sortingOptions = AssetOverviewComponent.DEFAULT_SORT_OPTIONS;
            this.sortDescending = this.beforeSearchSortDescending;
            this.sort = this.sortingOptions.find(option => option.getValue() === this.beforeSearchSort?.getValue()) ||
                this.sortingOptions[0];
        }
    }

    public onCollectionViewItemDoubleClicked(asset: AssetModel): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_ASSET_DETAILS)) {
            this.router.navigate([AppConstants.ASSETS_PATH, asset._id]);
        }
    }

    /**
     * On collection view item clicked get selected asset
     * @param {AssetModel} asset
     */
    public onCollectionViewItemClicked(asset: AssetModel): void {
        if (this.linkAssetsToProductsActive) {
            const index = this.selectedAssets.findIndex((assetId) => assetId === asset._id);
            if (index === -1) {
                this.selectedAssets.push(asset._id); // If asset not yet selected add to selected assets
            } else {
                this.selectedAssets.splice(index, 1); // if asset already selected remove from selected assets
            }
        }

        this.selectedAsset = asset;
    }

    /**
     * Handle page event
     */
    public onCollectionViewOptionsChanged(): void {
        this.setPageIndex();
    }

    /**
     * If we have a selected asset, return it
     * @returns {AssetModel}
     */
    public getSelectedAsset(): AssetModel {
        if (this.selectedAsset) {
            return this.selectedAsset;
        }
        return null;
    }

    /**
     * Deactivate linking assets to products by setting linkAssetsToProductsActive to false and resetting selected assets
     */
    public deactivateLinkingAssetsToProducts(): void {
        this.linkAssetsToProductsActive = false;
        this.selectedAssets = [];
        this.selectedAsset = null;
    }

    public onAddAssetsClicked(): void {
        const modalConfig = new FullModalConfig('New assets', 'Add new assets by uploading them.');
        const modal = this.fullModalService.open(AssetFormComponent, modalConfig);
        modal.afterClosed()
            .pipe(filter((refresh: boolean) => refresh))
            .subscribe(() => this.getAssets());
    }

    public onLinkAssetToProductClicked(): void {
        const modalData: ILinkAssetsToProductViewData = {selectedAssets: this.selectedAssets};
        const modalConfig = new FullModalConfig('Link assets to products', `Selected ${this.selectedAssets.length} asset(s).`, modalData);
        modalConfig.confirmClose = true;
        const modal = this.fullModalService.open(LinkAssetsToProductsViewComponent, modalConfig);
        modal.afterClosed()
            .pipe(filter((refresh: boolean) => refresh))
            .subscribe(() => this.getAssets());
    }

    public onAssetDeleted(): void {
        this.selectedAsset = null;
        this.getAssets();
    }

    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    public exportAssetsJob(): void {
        const dialogConfig = new NucDialogConfigModel('Export assets job',
            'Are you sure you want to export ALL assets to one or more archive files?');
        const dialog = this.dialogService.openDialog(dialogConfig);
        dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
        dialogConfig.addAction('Export', BUTTON_TYPE.PRIMARY).subscribe(() => {
            dialog.close();
            this.assetService.postJob(EAssetJobType.EXPORT_ASSETS_JOB, {}).subscribe({
                error: Toaster.handleApiError
            });
        });
    }
}
