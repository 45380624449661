import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged, finalize, map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {StaticContentRulesetPatchModel, StaticContentRulesetModel} from '../models/api/static-content-ruleset.model';
import {StaticContentRulesetService} from '../../../api/services/static-content-ruleset.service';
import {AppConstants} from '../../../app.constants';
import {Toaster} from '../../../classes/toaster.class';
import {DesignLibraryModel} from '../models/api/design-library.model';
import {EEngineType} from '../../../models/api/template.model';
import {IDropdownItem, IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {EFormStatus} from '../../../app.enums';
import {DesignLibraryApiService} from '../../../api/services/design-library.api.service';

export interface IFormatRulesetFormComponentData {
    ruleSet?: StaticContentRulesetModel;
}

export interface StaticContentRulesetFormGroup {
    name: FormControl<string>
    engineTypes: FormControl<DropdownItem<EEngineType>[]>;
    designLibraries: FormControl<DesignLibraryModel[]>;
}

@Component({
    selector: 'ruleset-form',
    templateUrl: './static-content-ruleset-form.component.html',
    styleUrls: ['./static-content-ruleset-form.component.scss']
})
export class StaticContentRulesetFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);

    public formGroup: FormGroup<StaticContentRulesetFormGroup>;

    public designLibraries: DesignLibraryModel[];
    public designLibrariesTotalItems = 0;
    public designLibrariesOffset: number;

    public engineTypes: IDropdownItem<EEngineType>[] = [
        new DropdownItem(EEngineType.INDESIGN, EEngineType.INDESIGN),
        new DropdownItem(EEngineType.SVG, EEngineType.SVG)];

    private saveButtonConfig: ButtonConfig;
    private onDestroySubject = new Subject<void>();

    constructor(private fullModalService: FullModalService,
                private formatRulesetService: StaticContentRulesetService,
                private designLibraryApiService: DesignLibraryApiService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IFormatRulesetFormComponentData) {
    }

    public ngOnInit(): void {
        this.initForm();
        this.initButtons();
    }

    private initForm(): void {
        this.formGroup = new FormGroup<StaticContentRulesetFormGroup>({
            name: new FormControl(this.modalData.ruleSet?.name, Validators.required),
            engineTypes: new FormControl(
                this.modalData.ruleSet?.engineTypes?.map((e) => new DropdownItem(e, e)),
                Validators.required
            ),
            designLibraries: new FormControl({ value: undefined, disabled: true }, Validators.required)
        });
    }

    private initButtons(): void {
        this.saveButtonConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, this.modalData.ruleSet ? 'Save' : 'Create', false, false, true);
        const cancelButtonConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const saveAction = new FullModalActionModel(this.saveButtonConfig);
        const cancelAction = new FullModalActionModel(cancelButtonConfig);
        const actions = [
            cancelAction,
            saveAction,
        ];

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => this.saveRuleset());

        this.fullModalService.setModalActions(actions);

        this.formGroup.statusChanges.pipe(
            map((status) => status === EFormStatus.VALID),
            distinctUntilChanged(),
            takeUntil(this.onDestroySubject)
        ).subscribe((valid) => this.saveButtonConfig.disabled = !valid);
    }

    public requestDesignLibraries(event?: IDropdownRequestDataEvent): void {
        if (event) {
            this.designLibraries = [];
            this.designLibrariesTotalItems = 0;
            this.designLibrariesOffset = 0;
        }

        this.getDesignLibraries(event.search, this.formGroup.controls.engineTypes.value.map((v) => v.getValue()));
    }

    public resetDesignLibraries(items: IDropdownItem[]): void {
        this.designLibraries = [];
        this.designLibrariesTotalItems = 0;
        this.designLibrariesOffset = 0;

        if (items && items.length !==0) {
            this.getDesignLibraries(null, items.map((v) => v.getValue()) as EEngineType[])
        } else {
            this.formGroup.controls.designLibraries.disable();
        }
    }

    private getDesignLibraries(search?: string, engineTypes?: EEngineType[]): void {
        this.designLibraryApiService
            .find(
                AppConstants.PAGE_SIZE_MAX,
                this.designLibrariesOffset,
                undefined,
                undefined,
                search,
                engineTypes ? { engineTypes } : undefined
            )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.designLibrariesTotalItems = result.total;
                    this.designLibrariesOffset += result.items.length;

                    if (this.designLibrariesTotalItems === 0)
                        this.formGroup.controls.designLibraries.disable();
                    else
                        this.formGroup.controls.designLibraries.enable();

                    this.designLibraries = this.designLibraries
                        .filter(item => !result.items.some(newItem => newItem._id === item._id))
                        .concat(result.items);

                    if (this.modalData.ruleSet) {
                        const ruleSet = this.modalData.ruleSet;
                        const selectedLibraries = this.designLibraries.filter((library) =>
                            ruleSet.libraries.some((selectedLibraryId) => selectedLibraryId === library._id));
                        const update = {
                            designLibraries: selectedLibraries
                        };
                        this.formGroup.patchValue(update);
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private saveRuleset() {
        const formValue = this.formGroup.getRawValue();

        const body = new StaticContentRulesetPatchModel()
        body.name = formValue.name;
        body.engineTypes = formValue.engineTypes.map((e) => e.getValue())
        body.libraries = formValue.designLibraries.map((lib) => lib._id)

        const postOrPatch$ = this.modalData.ruleSet ?
            this.formatRulesetService.patchStaticContentRuleset(this.modalData.ruleSet._id, body) :
            this.formatRulesetService.postStaticContentRuleset(body);

        postOrPatch$
            .pipe(
                finalize(() => this.saveButtonConfig.loading = false),
                takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    Toaster.success(`Static content ruleset ${this.modalData.ruleSet ? 'updated' : 'created'} successfully`);
                    this.fullModalService.close(true);
                },
                error: Toaster.handleApiError
            });
    }
}
