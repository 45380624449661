import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AssetModel} from '../../models/api/asset.model';
import {AppConstants} from '../../app.constants';
import {SortDirection} from '@angular/material/sort';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {Subject, Subscription} from 'rxjs';
import {AssetService} from '../../api/services/asset.service';
import {TableActionTypeModel} from '../../models/ui/table-action-type.model';
import {EColumnDataType, EColumnSize, EColumnType, IActionClickEvent, ITableColumn} from '@relayter/rubber-duck';
import {CursorArray} from '../../api/api-cursor';
import {RLTableComponent} from '../rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {PaginatorService} from '../paginator/paginator.service';
import {takeUntil} from 'rxjs/operators';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {ApiConstants} from '../../api/api.constant';
import {DataFilterModel} from '../../models/ui/data-filter.model';
import {AdvancedFiltersDataService} from '../../api/services/advanced-filters.data-service';
import {EDataFieldCollectionName, EDataFieldTypes} from '../../app.enums';
import {FileTypeUtil} from '../../classes/file-type.util';

@Component({
    selector: 'link-asset-view',
    templateUrl: 'link-asset-view.component.html',
    styleUrls: ['link-asset-view.component.scss'],
    providers: [PaginatorService, AdvancedFiltersDataService]
})
export class LinkAssetViewComponent extends RLTableComponent implements OnInit, OnDestroy {
    @Output() public linkAsset: EventEmitter<AssetModel> = new EventEmitter<AssetModel>();

    public readonly PAGE_INDEX_DEFAULT: number = AppConstants.PAGE_INDEX_DEFAULT;

    public tableId = 'link-asset-table';
    private onDestroySubject = new Subject<void>();

    public tableColumns: ITableColumn[] = [
        {
            title: '',
            selectionColumnTitle: 'Thumbnail',
            key: 'files.thumbnail.url',
            type: EColumnType.THUMBNAIL,
            format: (value) => value ? value : AppConstants.ICONS.IMAGE_MAIN,
            clickable: true
        },{
            title: 'Filename',
            key: 'name',
            size: EColumnSize.LARGE,
            type: EColumnType.DEFAULT
        },{
            title: 'Type',
            key: 'type',
            format: (value) => value ? value.replace(new RegExp('^[.]+'), '').toLocaleUpperCase() : '',
            type: EColumnType.DEFAULT
        }];
    public actions: TableActionTypeModel[] = [AppConstants.TABLE_ACTION_TYPES.ADD];
    public pageIndex: number = AppConstants.PAGE_INDEX_DEFAULT;
    public pageSize: number = AppConstants.PAGE_SIZE_DEFAULT;
    public pageSizeOptions =
        AppConstants.PAGE_SIZE_OPTIONS.map((option) => new DropdownItem(`${option}`, option));
    public sortProperty: string = 'name';
    public sortOrder: SortDirection = 'asc';
    public items: AssetModel[];
    private cursorArray: CursorArray;
    public disableNextPage = true;

    public assetsSubscription: Subscription;

    public filterContext = EDataFieldCollectionName.ASSET;
    public dataFilters: DataFilterModel[] = [];
    constructor(private assetService: AssetService,
                private paginatorService: PaginatorService,
                private advancedFiltersDataService: AdvancedFiltersDataService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initDataFilters();

        this.cursorArray = new CursorArray(this.pageIndex, true);

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(pagination => {
                this.pageIndex = pagination.pageIndex;

                this.pageSize = pagination.pageSize;
                if (this.pageIndex === 1) {
                    this.resetCursorArray();
                }

                this.getAssets();
            });

        this.advancedFiltersDataService.getFilterValues()
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(filterValues => {
                if (this.filterValues !== filterValues) {
                    this.filterValues = filterValues;

                    this.setPageIndex();
                }
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initDataFilters(): void {
        this.dataFilters.push(new DataFilterModel('RIN', 'rin', EDataFieldTypes.STRING));
        const items = FileTypeUtil.getExtensionsFromFileCategories(FileTypeUtil.DOWNLOAD_CATEGORIES);

        const dropdownItems = items.map(item => new DropdownItem(item, item));
        this.dataFilters.push(new DataFilterModel('File type', 'type', EDataFieldTypes.ENUM, dropdownItems, {multiSelect: true, items}));
    }

    public getAssets(): void {
        if (this.assetsSubscription) this.assetsSubscription.unsubscribe();

        const cursor = this.cursorArray.getCursor(this.pageIndex);

        this.assetsSubscription = this.assetService.getAssets(
            this.sortOrder === 'desc',
            !this.searchValue ? this.sortProperty : null,
            this.searchValue,
            this.pageSize,
            0,
            this.filterValues,
            cursor
        ).pipe()
            .subscribe(
            (res: ARPagedResponseDataModel<AssetModel>) => {
                this.items = res.items;

                if (res.items.length > 0) {
                    this.disableNextPage = !res.hasNext;
                    this.setCursor();
                }
            },
            (err: ARApiError) => Toaster.handleApiError(err)
        );
    }

    /**
     * Responds to input on the asset name search field
     */
    public onAssetSearchChanged(value: string): void {
        if (this.searchValue !== value) {
            this.searchValue = value;

            this.setPageIndex();
        }
    }

    /**
     * On table action clicked
     */
    public handleTableRowAction(event: IActionClickEvent): void {
        if (event.action === AppConstants.TABLE_ACTION_TYPES.ADD) {
            const asset = this.items.find((item) => item._id === event.item._id);
            this.linkAsset.emit(asset);
        }
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private resetCursorArray(pageIndex = this.pageIndex): void {
        if (this.searchValue) {
            this.cursorArray.reset(pageIndex, true, EColumnDataType.NUMBER);
        } else {
            this.cursorArray.reset(pageIndex, false);
        }
    }

    private setCursor(): void {
        if (this.searchValue) {
            this.cursorArray.setCursor(this.pageIndex, ApiConstants.SEARCH_INDEX_SCORE, this.items[this.items.length - 1]);
        } else {
            this.cursorArray.setCursor(this.pageIndex, this.sortProperty, this.items[this.items.length - 1]);
        }
    }
}
