<div class="notification-centre-item">
    @switch (notification.type) {
        @case (ENotificationType.JOB_DOWNLOAD) {
            <div class="icon"
                 [class.failed]="notification.data.jobStatus === EJobStatus.FAILED"
                 [class.done]="notification.data.jobStatus === EJobStatus.DONE"
                 [class.loading]="notification.data.jobStatus === EJobStatus.IN_PROGRESS">

                <div class="background"></div>

                <i class="nucicon_download"></i>
            </div>
        }
        @default {
            <rl-user-icon-component
                [user]="notification.sender ? notification.sender : {fullName: 'Placeholder H.'}"></rl-user-icon-component>
        }
    }

    <div class="content">
        <p class="body-strong">{{ notification.title }}</p>
        <p class="body-strong">{{ notification.createdAt | RLDatePipe: showFullDate ? RLDatePipe.dateFormats.NOTIFICATION_FULL : RLDatePipe.dateFormats.NOTIFICATION_TIME }}</p>

        @if (selected) {
            <div class="details">
                @for (contentItem of notification.content; track $index) {
                    @if (contentItem.html) {
                        <!--new version-->
                        <div class="row" [innerHTML]="contentItem.html | safeNotificationHtml"></div>
                    } @else if (contentItem.action) {
                        <nuc-button-secondary [text]="contentItem.action.text"
                                              (click)="actionClicked.emit({notification: notification, action: contentItem.action}); $event.stopPropagation()"></nuc-button-secondary>
                    } @else if (contentItem.list) {
                        <div class="detail-list">
                            @for (listItem of contentItem.list; track $index) {
                                @if (listItem.html) {
                                    <!--new version-->
                                    <div class="row" [innerHTML]="listItem.html | safeNotificationHtml"></div>
                                } @else if (listItem.action) {
                                    @if (listItem.action.type === ENotificationActionType.LINK) {
                                        <a class="green" [href]="listItem.action.url">{{listItem.action.text}}</a>
                                    } @else {
                                        <nuc-button-secondary [text]="listItem.action.text"
                                                              (click)="actionClicked.emit({notification: notification, action: listItem.action}); $event.stopPropagation()"></nuc-button-secondary>
                                    }

                                }
                            }
                        </div>
                    }
                }
            </div>

        } @else {
            <div [innerHTML]="notification.content[0].html | safeNotificationHtml"></div>
        }

    </div>

    @if (notification.status === ENotificationStatus.UNREAD) {
        <div class="unread-indicator"></div>
    }
    @if (selected && notification.status !== ENotificationStatus.ARCHIVED) {
        <nuc-button-secondary (click)="archiveButtonClicked.emit(); $event.stopPropagation()"
                              class="close-button"
                              nucTooltip="Move to archive"
                              icon="nucicon_files_line">
        </nuc-button-secondary>
    }

</div>
