import {inject, Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {PublicationModel, PublicationPatchModel, PublicationPostModel} from '../../models/api/publication.model';
import {TransitionItemModel} from '../../models/api/transition-item.model';
import {StickyNoteModel, StickyNotePostModel} from '../../models/api/sticky-note.model';
import {AppConstants} from '../../app.constants';
import {StickyNoteCommentModel} from '../../models/api/sticky-note-comment.model';
import {CustomWorkflowFilterOptionModel} from '../../models/api/custom-workflow-filter-option.model';
import {RLCountsModel} from '../response/rl-counts.model';
import {CustomWorkflowFilterModel} from '../../models/api/custom-workflow-filter.model';
import {CampaignItemModel} from '../../models/api/campaign-item.model';
import {IQueryParam, QueryParams} from '../../classes/query-params';
import {SocketService} from './socket/socket.service';
import {filter, tap} from 'rxjs/operators';
import {ERoomTypes} from './socket/constants/socket-room-types';
import {JobModel} from '../../models/api/job.model';
import {Cursor} from '../api-cursor';
import {MonitoredJobsService} from './monitored-jobs.service';
import {SortDirection} from '@angular/material/sort';
import {EPublicationEventType} from './socket/constants/socket-events';
import {UserService} from './users.service';
import {Deserialize} from 'cerialize';
import {MonitoredTransitionsService} from './monitored-transitions.service';
import {ESignOffStatus, PublicationItemModel, SignOffUserModel} from '../../models/api/publication-item.model';
import {
    EPackageType
// eslint-disable-next-line max-len
} from '../../pages/relayter/campaigns/publication/custom-workflow/custom-workflow-files/custom-workflow-files-download/package-type/files-download-package-type.component';
import {ERequestMethod} from '../../app.enums';

export enum EPublicationJobType {
    COPY_PUBLICATION = 'COPY_PUBLICATION',
    PUBLICATION_DELETE_JOB = 'PUBLICATION_DELETE_JOB',
    PACKAGE_GENERATION = 'PACKAGE_GENERATION',
    ARRANGE_PAGES = 'ARRANGE_PAGES',
    NOTE_EXPORT_JOB = 'NOTE_EXPORT_JOB'
}

export interface IDeletePublicationJobData {
    campaignId: string;
    publicationId: string;
    name: string;
}

export interface ICopyPublicationJobData {
    publicationId: string;
}

export interface IDownloadPackageJobData {
    step: string;
    packageType: EPackageType;
    publicationItems: string[];
    publicationId: string;
    variantId?: string;
    layoutId?: string;
    size?: { width: number; height: number };
    format?: string;
}

export interface INoteExportJobData {
    publicationId: string;
    stepId: string;
    exportType: string;
    query: Record<string, any>;
}

export interface IArrangePagesJobData {
    publicationId: string;
    publicationItems: string[];
}

export type IPublicationJobData =
    IDeletePublicationJobData |
    ICopyPublicationJobData |
    IDownloadPackageJobData |
    INoteExportJobData |
    IArrangePagesJobData;

/**
 * PublicationsService
 * API Service concerning publications
 */
@Injectable({
    providedIn: 'root'
})
export class PublicationsService extends BaseApiRequestService {
    private socketService = inject(SocketService);
    private monitoredJobsService = inject(MonitoredJobsService);
    private monitoredTransitionsService = inject(MonitoredTransitionsService);
    private userService = inject(UserService);

    /**
     * Get the details of a publication that belongs to a campaign
     * @param {string} campaignId
     * @param {string} publicationId
     * @returns {Observable<PublicationModel>}
     */
    public getPublicationDetails(campaignId: string, publicationId: string): Observable<PublicationModel> {

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_CAMPAIGNS, campaignId, ApiConstants.API_METHOD_PUBLICATIONS, publicationId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, PublicationModel);
        });
    }

    public getPublications(campaignId: string, limit?: number, offset?: number,
                           sortDirection?: SortDirection, sort?: string, search?: string,
                           filters?: Record<string, any>,
                           cursor?: Cursor): Observable<ARPagedResponseDataModel<PublicationModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sort, sortDirection)
            .setSearchParams(search);

        if (cursor) queryParams.setCursor(cursor);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_CAMPAIGNS, campaignId, ApiConstants.API_METHOD_PUBLICATIONS], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, PublicationModel);
        });
    }

    /**
     * Post a new publication to a campaign
     * @param {string} id
     * @param {PublicationPostModel} publication
     * @returns {Observable<PublicationModel>}
     */
    public postPublicationsForCampaign(id: string, publication: PublicationPostModel): Observable<PublicationModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_CAMPAIGNS, id, ApiConstants.API_METHOD_PUBLICATIONS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = publication;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, PublicationModel);
        });
    }

    public updatePublicationsForCampaign(campaignId: string, publicationId: string, body: PublicationPatchModel): Observable<PublicationModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            campaignId,
            ApiConstants.API_METHOD_PUBLICATIONS,
            publicationId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = body;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, PublicationModel);
        });
    }

    /**
     * Schedules a workflow transition item
     * @param {string} publicationId
     * @param {string} transitionId
     * @param {any} data
     * @returns {Observable<TransitionItemModel>}
     */
    public postTransitionItem(publicationId: string, transitionId: string, data: any): Observable<TransitionItemModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PUBLICATIONS,
            publicationId,
            ApiConstants.API_METHOD_TRANSITION_ITEMS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ERequestMethod.POST;
        options.url = url;
        options.body = {transitionId, data};
        return new Observable<TransitionItemModel>((obs) => {
            this.handleDetailResponse(options, obs, TransitionItemModel);
        });
    }

    /**
     * Adds a sign off user to the publication items
     *
     * @param {string} publicationId
     * @param {string[]} publicationItemIds
     * @param {ESignOffStatus} status
     * @returns {Observable<SignOffUserModel>}
     */
    public postSignOffUser(publicationId: string,
                           publicationItemIds: string[],
                           status: ESignOffStatus): Observable<ARPagedResponseDataModel<SignOffUserModel>> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PUBLICATIONS,
            publicationId,
            ApiConstants.API_METHOD_SIGN_OFF_USERS
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ERequestMethod.POST;
        options.url = url;
        options.body = {status, publicationItemIds};

        return new Observable<ARPagedResponseDataModel<SignOffUserModel>>((obs) => {
            this.handleArrayResponse(options, obs, SignOffUserModel);
        });
    }

    /**
     * Get a paged list of sticky notes optionally with params to filter
     */
    public getStickyNotesForPublication(publicationId: string,
                                        stepId?: string,
                                        stickyNoteFilters?: IQueryParam[],
                                        cursor?: Cursor,
                                        limit: number = AppConstants.PAGE_SIZE_MAX,
                                        offset: number = 0,
                                        sortProperty?: string,
                                        sortOrder?: SortDirection,
                                        search?: string): Observable<ARPagedResponseDataModel<StickyNoteModel>> {

        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .addParam('workflowStepId', stepId)
            .setSearchParams(search)
            .setCursor(cursor);

        if (stickyNoteFilters) {
            for (const stickyNoteFilter of stickyNoteFilters) {
                queryParams.addParam(stickyNoteFilter.queryParam, stickyNoteFilter.value);
            }
        }

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PUBLICATIONS,
            publicationId,
            ApiConstants.API_METHOD_STICKY_NOTES], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, StickyNoteModel);
        });
    }

    /**
     * Get a paged list of sticky notes for a publication item with params to filter
     */
    public getStickyNotesForPublicationItem(publicationId: string,
                                            itemId: string,
                                            stickyNoteFilters?: IQueryParam[],
                                            limit = AppConstants.PAGE_SIZE_MAX,
                                            offset = 0,
                                            sortProperty?: string,
                                            sortOrder?: SortDirection): Observable<ARPagedResponseDataModel<StickyNoteModel>> {

        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder);

        if (stickyNoteFilters) {
            for (const stickyNoteFilter of stickyNoteFilters) {
                queryParams.addParam(stickyNoteFilter.queryParam, stickyNoteFilter.value);
            }
        }

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PUBLICATIONS,
            publicationId,
            ApiConstants.API_METHOD_ITEMS,
            itemId,
            ApiConstants.API_METHOD_STICKY_NOTES], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, StickyNoteModel);
        });
    }

    /**
     * Get a sticky note
     * @param {string} publicationId
     * @param {string} stickyNoteId
     */
    public getStickyNote(publicationId: string, stickyNoteId: string): Observable<StickyNoteModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PUBLICATIONS,
            publicationId,
            ApiConstants.API_METHOD_STICKY_NOTES,
            stickyNoteId]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, StickyNoteModel);
        });
    }

    /**
     * Create a new sticky note
     * If addForRelated is true, we also create sticky notes for other publication items in the same step
     * that have the same campaignItem(stickyNote.campaignItem) laid out in their content
     */
    public postStickyNote(publicationId: string, itemId: string, stickyNote: StickyNotePostModel, componentId: string,
                          addForRelated = false, addToAllVariants = false): Observable<StickyNoteModel> {
        const queryParams = new QueryParams().addParam('addForRelated', addForRelated);
        queryParams.addParam('componentId', componentId);
        queryParams.addParam('addToAllVariants', addToAllVariants);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PUBLICATIONS,
            publicationId,
            ApiConstants.API_METHOD_ITEMS,
            itemId,
            ApiConstants.API_METHOD_STICKY_NOTES], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = stickyNote;

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, StickyNoteModel);
        });
    }

    /**
     * Update a sticky note for a publication item
     */
    public updateStickyNote(publicationId: string, stickyNote: StickyNoteModel, componentId: string): Observable<StickyNoteModel> {
        const queryParams = new QueryParams().addParam('componentId', componentId);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PUBLICATIONS,
            publicationId,
            ApiConstants.API_METHOD_STICKY_NOTES,
            stickyNote._id], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PUT;
        options.url = url;
        options.body = {};

        if (stickyNote.message) {
            options.body['message'] = stickyNote.message;
        }

        if (stickyNote.status) {
            options.body['status'] = stickyNote.status;
        }

        if (stickyNote.campaignItem) {
            options.body['campaignItem'] = stickyNote.campaignItem._id || '';
        }

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, StickyNoteModel);
        });
    }

    /**
     * Creates a new comment on the sticky
     * @param {string} publicationId
     * @param {string} stickyNoteId
     * @param {string} componentId
     * @param {object} body
     * @param {string} body.message
     * @param {string[]} [body.files]
     * @returns {Observable<StickyNoteCommentModel>}
     */
    public postCommentOnSticky(publicationId: string,
                               stickyNoteId: string,
                               componentId: string,
                               body: object): Observable<StickyNoteCommentModel> {
        const queryParams = new QueryParams().addParam('componentId', componentId);
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_METHOD_PUBLICATIONS,
            publicationId,
            ApiConstants.API_METHOD_STICKY_NOTES,
            stickyNoteId,
            ApiConstants.API_METHOD_COMMENTS
        ], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = body;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, StickyNoteCommentModel);
        });
    }

    /**
     * Get the comments for a sticky note
     * @param {string} publicationId
     * @param {string} stickyNoteId
     * @param {string} componentId
     * @param {number} limit
     * @param {number} offset
     * @returns {Observable<ARPagedResponseDataModel<StickyNoteCommentModel>>}
     */
    public getCommentsForStickyNote(publicationId: string,
                                    stickyNoteId: string,
                                    componentId: string,
                                    limit: number = AppConstants.PAGE_SIZE_DEFAULT,
                                    offset: number = 0): Observable<ARPagedResponseDataModel<StickyNoteCommentModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .addParam('componentId', componentId);
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_METHOD_PUBLICATIONS,
            publicationId,
            ApiConstants.API_METHOD_STICKY_NOTES,
            stickyNoteId,
            ApiConstants.API_METHOD_COMMENTS
        ], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse<StickyNoteCommentModel>(options, obs, StickyNoteCommentModel);
        });
    }

    public getPublicationItem(publicationId: string,
                              publicationItemId: string): Observable<PublicationItemModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_METHOD_PUBLICATIONS,
            publicationId,
            ApiConstants.API_METHOD_ITEMS,
            publicationItemId
        ]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, PublicationItemModel);
        });
    }

    /**
     * Get publication items count for publication id grouped by step and transitions
     * @param {string} publicationId
     * @param {object} [filterMap]
     * @return {Observable<RLCountsModel>}
     */
    public getItemsCountForPublication(
        publicationId: string,
        filterMap?: Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>): Observable<RLCountsModel> {

        const queryParams = new QueryParams();
        PublicationsService.addFiltersToParams(queryParams, filterMap);
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_METHOD_PUBLICATIONS,
            publicationId,
            ApiConstants.API_METHOD_ITEMSCOUNT], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, RLCountsModel);
        });
    }

    /**
     * Gets the campaign items assigned to the publication item
     * @param {string} publicationId
     * @param {string} publicationItemId
     * @param {string} [search]
     * @param {number} limit
     * @param {number} offset
     * @param {string} [sort]
     * @param {string} [sortType]
     * @return {Observable<ARPagedResponseDataModel<CampaignItemModel>>}
     */
    public getCampaignItemsForPublicationItem(publicationId: string,
                                              publicationItemId: string,
                                              search?: string,
                                              limit: number = AppConstants.PAGE_SIZE_MAX,
                                              offset: number = 0,
                                              sort?: string,
                                              sortType?: string): Observable<ARPagedResponseDataModel<CampaignItemModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sort, sortType)
            .setSearchParams(search);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_METHOD_PUBLICATIONS,
            publicationId,
            ApiConstants.API_METHOD_ITEMS,
            publicationItemId,
            ApiConstants.API_METHOD_CAMPAIGN_ITEMS], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, CampaignItemModel);
        });
    }

    /**
     * Updates a publication item
     * @param {object} patchBody
     * @param {string} publicationId
     * @param {string} publicationItemId
     * @return {Observable<PublicationItemModel>}
     */
    public patchPublicationItem(patchBody: Record<string, any>,
                                publicationId: string,
                                publicationItemId: string): Observable<PublicationItemModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_METHOD_PUBLICATIONS,
            publicationId,
            ApiConstants.API_METHOD_ITEMS,
            publicationItemId,
        ]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = patchBody;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, PublicationItemModel);
        });
    }

    /**
     * Maps filterMap to queryParams
     * @param {QueryParams} queryParams
     * @param {Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>} filterMap
     */
    public static addFiltersToParams(queryParams: QueryParams, filterMap: Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>): void {
        if (filterMap) {
            filterMap.forEach((values: CustomWorkflowFilterOptionModel[], key: CustomWorkflowFilterModel) => {
                if (values.length > 0) {
                    queryParams.addParam(key.queryParam, values.map((value: CustomWorkflowFilterOptionModel) => value.key));
                }
            });
        }
    }

    /**
     * Join room for any TRANSITION_ITEM_UPDATE messages for this publication and
     * pass through any messages to the publicationUpdate$ subject
     */
    public registerForPublicationUpdates(publicationId: string): void {
        const user = this.userService.getLoggedInUser();
        if (user) {
            this.socketService.joinRoom(`${user.team._id}/${ERoomTypes.PUBLICATIONS_ROOM}/${publicationId}`).pipe(
                filter((event) => event.type === EPublicationEventType.TRANSITION_ITEM_UPDATE)
            ).subscribe((message) => {
                this.monitoredTransitionsService.updateTransitionMonitor(Deserialize(message.data, TransitionItemModel));
                this.socketService.publicationUpdates$.next(message);
            });
        }
    }

    /**
     * Sends a 'leave-room' event. Now the publicationSubject$ no longer receives updates for this publication
     */
    public unregisterForPublicationUpdates(publicationId: string): void {
        const user = this.userService.getLoggedInUser();
        if (user) {
            this.socketService.leaveRoom(`${user.team._id}/${ERoomTypes.PUBLICATIONS_ROOM}/${publicationId}`);
        }
        this.monitoredTransitionsService.removeAllMonitoredTransitions();
    }

    public postJob(jobType: EPublicationJobType, jobData: IPublicationJobData): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PUBLICATIONS,
            ApiConstants.API_METHOD_JOBS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        }).pipe(
            tap((job: JobModel) => this.monitoredJobsService.addJobToMonitor(job))
        );
    }
}
