import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {RLValidatorConstants} from '../../classes/validators/rl-validators.constant';
import {EUploadStatus} from '../../components/upload-file-component/upload.model';
import {IUploadUpdate} from '../../components/upload-file-component/upload-file.component';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {distinctUntilChanged, finalize, map, startWith} from 'rxjs/operators';
import {JobModel} from '../../models/api/job.model';
import {ARApiError, ARLogger} from '@relayter/core';
import FontsService, {EFontJobType, IFontJobData} from '../../api/services/fonts.service';
import {FontModel} from '../../models/api/font.model';
import {EFormStatus} from '../../app.enums';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../classes/toaster.class';
import {FileTypeUtil} from '../../classes/file-type.util';

export interface IFontFormData {
    font?: FontModel;
}

export interface ILibraryFormResult {
    jobId?: string;
}

class FontFormGroup {
    family: FormControl<string>
}

@Component({
    selector: 'rl-font-form-component',
    templateUrl: 'font-form.component.html',
    styleUrls: ['font-form.component.scss']
})
export class FontFormComponent implements OnInit {
    public formGroup: FormGroup<FontFormGroup>;
    private destroyRef = inject(DestroyRef);
    public modalData: IFontFormData = inject(NUC_FULL_MODAL_DATA);
    private fullModalService: FullModalService = inject(FullModalService);
    private fontsService: FontsService = inject(FontsService);

    public fileTypeCategories = [FileTypeUtil.CATEGORIES.FONT_FILE];

    private saveButton: ButtonConfig;

    private uploadData: IUploadUpdate;

    public uploadUpdateSubject = new BehaviorSubject<IUploadUpdate>(null);

    public ngOnInit(): void {
        this.setupForm();
        this.setupModalActions();
        this.trackStatus();
    }

    private setupForm(): void {
        this.formGroup = new FormGroup({
            family: new FormControl(this.modalData.font?.family, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
        });
    }

    private setupModalActions(): void {
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null);
        const saveAction = new FullModalActionModel(this.saveButton);
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.subscribe(() => {
            this.saveButton.loading = true;
            this.modalData.font ? this.updateFont() : this.createFont();
        });

        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }

    private trackStatus(): void {
        combineLatest([
            this.formGroup.statusChanges.pipe(
                startWith(this.formGroup.status),
                distinctUntilChanged(),
                map((status) => status === EFormStatus.VALID)
            ),
            this.uploadUpdateSubject.pipe(map((uploadUpdate) => {
                this.uploadData = uploadUpdate;
                return uploadUpdate?.status === EUploadStatus.Done || (this.modalData.font && !uploadUpdate);
            }))])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([formValid, uploadValid]) => this.saveButton.disabled = !formValid || !uploadValid);
    }

    private updateFont(): void {
        if (this.uploadData?.s3Key) {
            const jobData =
                {fontId: this.modalData.font._id, family: this.formGroup.value.family.trim(), s3Key: this.uploadData.s3Key} as IFontJobData;
            this.postFontJob(EFontJobType.UPDATE_FONT_JOB, jobData);
        } else {
            this.fontsService.patch(this.modalData.font._id, {family: this.formGroup.value.family.trim()})
                .pipe(
                    finalize(() => this.saveButton.loading = false),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: () => {
                        Toaster.success('After Effects project file updated successfully');
                        this.fullModalService.close({});
                    },
                    error: Toaster.handleApiError
                });
        }
    }

    private createFont(): void {
        const jobData = {family: this.formGroup.value.family.trim(), s3Key: this.uploadData.s3Key} as IFontJobData;
        this.postFontJob(EFontJobType.ADD_FONT_JOB, jobData);
    }

    private postFontJob(jobType: EFontJobType, jobData: IFontJobData): void {
        this.fontsService.postJob(jobType, jobData).subscribe({
            next: (job: JobModel) => {
                ARLogger.debug('Font Job scheduled: ' + job._id);
                this.fullModalService.close({jobId: job._id} as ILibraryFormResult); // close with job id for refreshing
            },
            error: (error) => this.handleError(error)
        });
    }

    private handleError(error: ARApiError): void {
        Toaster.handleApiError(error);
        this.saveButton.loading = false;
    }
}
