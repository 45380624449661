import {autoserialize, autoserializeAs} from 'cerialize';
import {EJobStatus} from './job.model';
import {UserModel} from './user.model';

export enum ENotificationActionType {
    DOWNLOAD = 'DOWNLOAD',
    NAVIGATE = 'NAVIGATE',
    LINK = 'LINK'
}

export enum ENotificationType {
    DOWNLOAD = 'DOWNLOAD',
    HTML = 'HTML'
}

export enum ENotificationStatus {
    READ = 'READ',
    UNREAD = 'UNREAD',
    ARCHIVED = 'ARCHIVED'
}

export enum ENotificationType {
    JOB = 'JOB',
    JOB_DOWNLOAD = 'JOB_DOWNLOAD',
    WORKFLOW_BETWEEN_STEPS_TRANSITION = 'WORKFLOW_BETWEEN_STEPS_TRANSITION',
    PUBLICATION_ITEM_UPLOAD = 'PUBLICATION_ITEM_UPLOAD',
    WORKFLOW_DELIVERY_LINKS = 'WORKFLOW_DELIVERY_LINKS',
    NOTE_UPDATE = 'NOTE_UPDATE',
    STICKY_COMMENT = 'STICKY_COMMENT',
    DEADLINE_NOTIFICATION_BEFORE = 'DEADLINE_NOTIFICATION_BEFORE', // reminder
    DEADLINE_NOTIFICATION_AFTER = 'DEADLINE_NOTIFICATION_AFTER', // warning
}

export class NotificationContentItemModel {
    @autoserialize public html: string;
    @autoserialize public action: NotificationActionModel;
    @autoserialize public list: { html: string, actions: NotificationActionModel }[];
}

export class NotificationActionModel {
    @autoserialize title?: string;
    @autoserialize type: ENotificationActionType;
    @autoserialize url: string;
    @autoserialize text?: string;
}

export class NotificationDataModel {
    @autoserialize public jobStatus: EJobStatus;
}

export class NotificationModel {
    @autoserialize public _id: string;
    @autoserialize public title: string;
    @autoserialize public type: ENotificationType;
    @autoserialize public content: NotificationContentItemModel[];
    @autoserialize public status: ENotificationStatus;
    @autoserializeAs(NotificationDataModel) public data: NotificationDataModel;
    @autoserializeAs(UserModel) public sender: UserModel;
    @autoserializeAs(Date) public expires: Date;
    @autoserializeAs(Date) public createdAt: Date;
    @autoserializeAs(Date) public updatedAt: Date;
}
